/**
 * @author Iker Hurtado
 *
 * @fileOverview File holding the StructureBuilder module main classes
 */

import UIComponent from '../common/UIComponent.js'
import Structure from '../common/Structure.js'
import StructureViewer from "../structure-viewer/StructureViewer.js";
import State from './State.js'
import * as util from '../common/util.js'
import CoordinatesField from './CoordinatesField.js'
import SupercellField from './SupercellField.js'
import BasisAtoms from "./BasisAtoms.js";
import SurfaceConstructionField from './SurfaceConstructionField.js';
import * as ModalPopup from '../common/ModalPopup.js'
import * as UserMsgBox from '../common/UserMsgBox.js'
import * as AppState from '../State.js'
import binIcon from '../../img/binicon.png'
import StructureInfo from "./StructureInfo.js"
import StructureSelector from "./StructureSelector.js"
import Primitive from "./Primitive.js"
import BZViewer from "../bz-viewer/BZViewer.js"
import Accordion from "../control-generator-mod/Accordion.js"


const DEFAULT_MODE = 'default'
// const IMPORT_MODE = 'import'
// const EXPORT_MODE = 'export'
// const RESET_MODE = 'reset'

const STRUCT_TOO_BIG_MSG = 'Repeat operation not performed: the resulting structure is too big'
const SURFACE_GENERATION_ERROR_MSG = 'The slab cannot be built from the provided data'
const ONLY_FOR_PERIODIC_MSG = 'This functionality is only supported for periodic structures!'


/**
 * StructureBuilder application module UI component
 */
export default class StructureBuilderMod extends UIComponent{

	constructor() {
      super('div', '.StructureBuilderMod')
      this.setHTML(`
				<div class="structure-viewer-info">

					<div class="StructureSelector-ph"> </div>

        	<div class="StructureViewer"> </div>

          <div class="shortcuts-box">
						<table>
								<tr> <td>Undo/Redo</td> <td>Ctrl+Z / Ctrl+Y</td> </tr>

								<tr> <td class="shortcuts-title" colspan="2"><b>Structure modification</b> (<i>Edit structure</i> button ON)</td> </tr>
								<tr> <td>Move Atom</td> <td>Atom dragging (mouse)</td> </tr>
								<tr> <td>Delete Atom</td> <td>D + Click on atom</td> </tr>

						</table>
						<table>
							<tr> <td class="shortcuts-title" colspan="2"><b>Atoms selection and measurements</b></td> </tr>
							<tr> <td>Atom Distance</td> <td>Shift + Click on two atoms</td> </tr>
							<tr> <td>Atom Angle</td> <td>Shift + Click on three atoms</td> </tr>
							<tr> <td>Torsion Angle </td> <td>Shift + Click on four atoms</td> </tr>
						</table>
					</div>

					<div class="BZViewer"></div>

				</div>

        <div class="right-side-panel">

          <button class='top-right-btn'> Import </button>
          <button class='top-right-btn'> Export </button>
          <!--button class="top-right-btn"> Reset </button-->
					<div class="Accordion-ph"> </div>

          <!--div class="LatticeVectors-ph"></div-->

					<div class="LatticeOperations"></div>

          <div class="BasisAtoms-ph"></div>

        </div>
      `)

      // state
      this.structure = new Structure()
      this.editable = true
      this.mode = DEFAULT_MODE

			this.state = new State()

      this.importerBox = new GeometryImporter(this.state)
      this.exporterBox = new Exporter()
      // this.resetPanel = new ResetPanel(this.state)

      const buttons = this.getElements('.top-right-btn')
      // Import button
      buttons[0].addEventListener( 'click', _ => {
        ModalPopup.setModalComponent(this.importerBox.e)
        ModalPopup.showModal(true)
      })

      // Export button
      buttons[1].addEventListener( 'click', _ => {
        ModalPopup.setModalComponent(this.exporterBox.e)
        ModalPopup.showModal(true)
      })

      // Reset button
      // buttons[2].addEventListener( 'click', e => {
      //   ModalPopup.setModalComponent(this.resetPanel.e)
      //   ModalPopup.showModal(true)
      // })

			this.accordion = new Accordion('structure-builder-side')
			this.getElement('.Accordion-ph').appendChild(this.accordion.e)

      this.lattVectors = new LatticeVectors(this.state)
			this.accordion.setSection('Lattice Vectors',this.lattVectors.e)
      this.state.subscribeToStructure( this.lattVectors )

      this.basisAtoms = new BasisAtoms(this.state)
      this.accordion.setSection('Atomic Positions',this.basisAtoms.e)
      this.state.subscribeToAll( this.basisAtoms )

			this.structureInfo = new StructureInfo(this.state)
			this.accordion.setSection('Structure Info',this.structureInfo.e)
			this.state.subscribeToStructure( this.structureInfo)
			this.accordion.foldSection(2)

      this.scell = new Supercell(this.state)
      this.accordion.setSection('Supercell',this.scell.e)
      this.state.subscribeToStructure( this.scell )
			this.accordion.foldSection(3)

			this.primitive = new Primitive(this.state)
			this.accordion.setSection('Standardized Cells',this.primitive.e)
			this.state.subscribeToStructure( this.primitive )
			this.accordion.foldSection(4)

      this.surfacePrep = new Surface(this.state)
      this.accordion.setSection('Surface (Slab) Construction',this.surfacePrep.e)
			this.state.subscribeToStructure(this.surfacePrep)
			this.accordion.foldSection(5)


			// this.collapsible.content.appendChild(this.structureInfo.e)
			// this.getElement('.Collapsible').appendChild(this.collapsible.e)
			// this.getElement('.Structure-Info').appendChild(this.structureInfo.e)


			this.structureSelector = new StructureSelector(this.state)
			this.getElement('.StructureSelector-ph').appendChild(this.structureSelector.e)
			this.state.subscribeToStructure( this.structureSelector )

      this.state.subscribeToStructure( this )

			// let hide = () => {
			// 	let table = this.getElement('table')
			// 	if (table.style.display === 'none') {
			// 	  table.style.display	= 'block'
			// 	} else {
			// 		table.style.display = 'none'
			// 	}
			// }
			// let shButton = this.getElement('span.addon')
			// console.log(shButton)
			// shButton.addEventListener('click',hide)
  }


  /**
   * Module initialization method.
   * Sets up the structure viewer and loads the initial structure
   */
  init(){
    this.viewer = new StructureViewer(this.getElement('.StructureViewer'), true, this.state)
		this.bzviewer = new BZViewer(this.getElement('.BZViewer'))
    this.state.subscribeToAll( this.viewer )
		this.state.subscribeToStructure( this.bzviewer )
    this.state.subscribeToAtomHighlight( this.viewer )

    util.loadDataFile('CdSrTa.in', 'text', text => {
			// This unifies the file load for the initialization.
			let file = new File([text],'CdSrTa.in',{type: "text/plain"})
			this.importerBox.sendGeometryToParse(file, 'CdSrTa.in').then()
      // this.state.setStructure(util.getStructureFromFileContent('CdSrTa.in', text))
      // processUrlHash() No URL params support at the beginning (without changing the URL fragment)
    }).then()

    document.addEventListener('keydown', e => {
      if (e.code === 'KeyZ' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault()
        this.state.undo()
      }else if (e.code === 'KeyY' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault()
        this.state.redo()
      }
    })

  }


  /**
   * Processes the URL fragment for this module
   * @param  {string} fragment
   */
  processUrlFragment(fragment){

      if (fragment.startsWith('repeat')){

        if (!this.state.getStructure().isAPeriodicSystem()){
          UserMsgBox.setError(ONLY_FOR_PERIODIC_MSG)
          return
        }

        let supercellDim = fragment.substring(fragment.indexOf(':')+1)
        const dimArray = supercellDim.split(':')
        if (!this.state.repeatStructure(dimArray))
          UserMsgBox.setError(STRUCT_TOO_BIG_MSG)
      }
    }


  /**
   * Updates the component to a new structure
   * Listener for the 'new structure' module level event
   * @param {Structure} structure
   */
  setNewStructure(structure){
    log('setNewStructure', structure, structure.fileSource)

    // let status
    // if (structure.fileSource === undefined)
    //   status = 'New Structure'
    // else
    //   status = (structure.fileSource === '' ? 'No file name' :
    //     'FILE NAME: '+structure.fileSource)
    // let h1 = this.statusLabel.getElementsByClassName('h1-title')
		// console.log(h1)
		// h1[0].innerHTML= status

    this.exporterBox.updateFilesData(structure)
  }

	selectStructure(structure){
		this.setNewStructure(structure)
	}


  /**
   * Updates the component to a structure change
   * Listener for the 'update structure' module level event
   * @param {Structure} structure
   * @param {object} _change
   */
  updateStructure(structure, _change ){
    this.exporterBox.updateFilesData(structure)
  }


  /**
   * Returns the current structure in the module state
   * @return {Structure}
   */
  getCurrentStructure(){
    return this.state.getStructure()
  }


  /**
   * Updates the component for a code change (application level event)
   * @param  {string} _code
   */
  updateForCode(_code){
    this.exporterBox.updateFilesData(this.state.getStructure())
  }


  /**
   * Updates the component for a setting change (application level event)
   * @param  {object} _newSettings
   */
  updateSettings(_newSettings){
    //log('SBM updateSettings', newSettings)
		this.structureInfo.updateStructureInfo()
    this.lattVectors.updateVectorsDecimals()
    this.basisAtoms.update()
  }

}


/**
 * Structure geometry importer UI component
 */
class GeometryImporter extends UIComponent{

  /**
   * @param  {State} moduleState
   *   Object representing the state of the Structure builder module
   */
  constructor(moduleState){
    super('span', '.GeometryImporter')

    // file format supported
		let isAdvancedUpload = function() {
			let div = document.createElement('div');
			return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
		}()

    this.setHTML(`
				<form class="box" method="post" action="" enctype="multipart/form-data">
					<div class="box__input">
						<input class="box__file" type="file" name="files[]" id="file" data-multiple-caption="{count} files selected" multiple /><br>
						<label for="file"><strong>Choose a file</strong><span class="box__dragndrop"> or drag it here</span>.</label>
					</div>
					<div class="box__uploading">Uploading&hellip;</div>
				</form>
    `)


		this.moduleState = moduleState
		let form = this.getElement('.box')

		if (isAdvancedUpload) {
		  form.classList.add('has-advanced-upload')
		}

		if (isAdvancedUpload) {
			['drag','dragstart','dragend','dragover','dragenter','dragleave','drop'].forEach(eventName => {
			  form.addEventListener(eventName, e => {
					e.preventDefault()
				  e.stopPropagation()
				}, false)
			});

			['dragover', 'dragenter'].forEach(eventName => {
				form.addEventListener(eventName, _ => {
					form.classList.add('is-dragover')
				})
			});

			['dragleave', 'dragend', 'drop'].forEach(eventName => {
				form.addEventListener(eventName, _ => {
					form.classList.remove('is-dragover')
				})
			});

			form.addEventListener('drop',e => {
				let dt = e.dataTransfer
	  		let files = dt.files;
				this.uploadFiles(([...files]))
				// this.uploadFile(files[0])
				// this.sendGeometryToParse(files[0])

			},false)
		}


    // Main event: a file to be uploaded is selected
    this.fileInput = this.getElement('input')
    this.fileInput.addEventListener( 'change', e => {

      let files = e.target.files
			this.uploadFiles(([...files]))

			// ([...files]).forEach(file => {
			// 	console.log(file)
			// 	this.uploadFiles(files)
			// });
    },false)

/* The parsing could be done on the client for the .in files in aq more efficient way
    this.getElement('#Importer').addEventListener( 'change', e => {
      let file = e.target.files[0]
      let reader = new FileReader()
      // when the file is read it triggers the onload event above.
      reader.readAsText(file)
      reader.addEventListener('load', e => {
        State.setStructure(getStructureFromFileContent(file.name, e.target.result))
      })
    })
    */

  }

	uploadFiles (files) {
		const FILE_EXTENSIONS = new Map([
			['in', 'FHI-aims'],
			['pwi', 'Quantum Espresso'],
			['cif', 'CIF-file'],
			['xyz', 'XYZ-file']
		])

		const FILE_NAMES = new Map([
			['input.xml','exciting'],
			['POSCAR','VASP']
		])

		let promises = []
		let ib = 0
		files.forEach(file => {
			promises.push( new Promise((resolve) => {

					// Button creation
					let fileExt
					if (file.name.includes('.'))
							fileExt = file.name.substring(file.name.lastIndexOf('.')+1)
					if (!FILE_NAMES.has(file.name) && !(new Set([...FILE_EXTENSIONS.keys(), 'next_step'])).has(fileExt) ) {
						ib++
						const extChooser = new UIComponent('div', '.extensionChooser')
						let html = `<div>The <b>file extension</b> for ${file.name} is <b>not valid</b>.`+
							'The below geometry types are supported, please, choose one:</div> <div class="radios-ph">'

						FILE_EXTENSIONS.forEach( (value, key) => {
							html += `<input type="radio" name="file-extension" id="${value}" value="${key}"> ${value} <br>`
						})
						FILE_NAMES.forEach( (value, key) => {
							html += `<input type="radio" name="file-extension" id="${value}" value="${key}"> ${value} <br>`
						})
						html += `</div>  <div class="button-ph"><button class="button${ib}">Done</button></div>`
						extChooser.setHTML(html)

						const button = extChooser.getElement('.button'+ib)

						button.addEventListener( 'click', buttonEvent => {
							let result
							let value = extChooser.e.querySelector('input:checked').value
							if (FILE_EXTENSIONS.has(value)) {
								result = file.name + '.' + value
							}	else {
								result = value
							}
							buttonEvent.target.parentElement.parentElement.remove()
							resolve(result)

						})
						let addMP = ib !== 1
						ModalPopup.setModalComponent(extChooser.e,addMP)
						// ModalPopup.showModal()
					}else {
						resolve(file.name)
					}
			}))

		})

		// console.log(promises)
		Promise.all(promises).then( names => {
			// console.log(names)
			ModalPopup.hideModal()
			for (let i = 0; i < names.length; i++) {
				let file = files[i]
				this.sendGeometryToParse(file, names[i]).then()
			}
		})

	}


  /**
   * Sends the geometry file to parse into the server side
   * @param  {File} file File data
   * @param  {string} fileName
   */
  async sendGeometryToParse(file, fileName){

    let formData = new FormData()
    // let fName = (fileName === undefined ? file.name : fileName)
		// console.log('sendGeometryToParse',file)
    formData.set('file', file, fileName)
    let response = await fetch('/parse-geometry-file', {
      method: 'POST',
      body: formData // multipart/form-data
    })
    if (!response.ok){
      this.fileInput.value = ''
      return
    }

    const text = await response.text()
    if (text.includes('ErrorParsingGeometryFile')) {
      this.fileInput.value = ''
      UserMsgBox.setError(`${file.name} parsing: something went wrong. Backend error is given below<br>${text}`)
      return
    }
		// console.log(text)
    const structureData = JSON.parse(text)
    this.moduleState.setStructure(util.getStructureFromJSON(structureData))

    // ModalPopup.hideModal()
  }

}


/**
 * Structure geometry exporter UI component
 */
class Exporter extends UIComponent{

  constructor(){
    super('span', '')

    this.setHTML(`
        <div class="explanation" style="line-height: 180%;">You can export the structure data as a file:
        <br>
        <a class="download-link"  href=""> </a> <span>(cartesian)</span>
        <br>
        <span class="fract-line">
          <a class="download-link" href=""> </a> <span>(fractional)</span>
        </span>
        </div>
    `)
  }


  /**
   * Updates the file data in the download links
   * @param  {Structure} structure
   */
  updateFilesData(structure){
    const filesData = util.getInputTextFilesURL(structure, !AppState.isExcitingCode())
    let i = 0
    this.getElements('a').forEach( aElement => {
      const fileName = AppState.isExcitingCode() ? 'input.xml' : 'geometry.in'
      aElement.setAttribute('download', fileName)
      aElement.innerHTML = fileName
      this.getElement('.fract-line').style.display = (filesData[i] === undefined ? 'none' : 'inline')
      aElement.href = filesData[i++]
    })
  }
}


// /**
//  * Simple panel warning the user before the structure reset
//  */
// class ResetPanel extends UIComponent{
//
//   /**
//    * @param  {State} moduleState
//    *   Object representing the state of the Structure builder module
//    */
//   constructor(moduleState){
//     super('span')//this.e = document.createElement('span')
//
//     this.setHTML(`
//         Are you sure you want to reset the structure data? <br><br>
//         <div style="text-align: center"><button>Confirm</button><div>
//     `)
//     // Reset structure
//     this.getElement('button').addEventListener( 'click', _ => {
//       moduleState.resetStructure()
//       ModalPopup.hideModal()
//     })
//   }
//
// }


/**
 * UI panel showing the structure lattice vectors
 */
class LatticeVectors extends UIComponent{

  /**
   * @param  {State} moduleState
   *   Object representing the state of the Structure builder module
   */
  constructor(moduleState){

    super('div', '#LatticeVectors')

    this.setHTML(`
      <div class="div-table regular-view">
        <div class="div-table-row header">
          <div class="div-table-col atom-order"></div>
          <div class="div-table-col vector">All numbers in units of &#197;</div>
          <div class="div-table-col">Constraint</div>
        </div>
        <div class="div-table-row">
          <div class="div-table-col atom-order" style="font-weight:bolder">a:</div>           
          <div class="div-table-col vector"><span class="lattice-vector-wrapper"></span> </div>
          <div class="div-table-col"><input type="checkbox" class="constraint" disabled/></div>
        </div>
        <div class="div-table-row">
          <div class="div-table-col atom-order" style="font-weight:bolder">b:</div>           
          <div class="div-table-col vector"><span class="lattice-vector-wrapper"></span> </div>
          <div class="div-table-col"><input type="checkbox" class="constraint" disabled/></div>
        </div>
        <div class="div-table-row">
          <div class="div-table-col atom-order" style="font-weight:bolder">c:</div>           
          <div class="div-table-col vector"><span class="lattice-vector-wrapper"></span> </div>
          <div class="div-table-col"><input type="checkbox" class="constraint" disabled/></div>
        </div>
        <div class="lattice-scale-checkbox">
          <input type="checkbox" checked >Scale atom positions with lattice vectors
        </div>
				<div class="lattice-scale-checkbox">
					<img class="img-button" src="${binIcon}" width="11px" alt="Remove"/> Remove lattice vectors
				</div>
      </div>
      <div class="hidden-view" style="padding-top:10px">
        <button class="add-lattice-vectors">Add lattice vectors</button>
      </div>
    `)

    this.moduleState = moduleState

    // Add lattice vectors button
    this.getElement('.add-lattice-vectors').addEventListener('click', _ => {
      this.moduleState.updateLatticeVectors([[10, 0, 0], [0, 10, 0], [0, 0, 10]])
    })

    // Remove lattice vectors button
    this.getElement('.img-button').addEventListener('click', _ => {
      // Remove lattice vectors
      this.moduleState.updateLatticeVectors(undefined)
    })


    this.vectorFields = []

    const vectorElements = this.getElements('.lattice-vector-wrapper')
    vectorElements.forEach( vElement => {
      const newField = new CoordinatesField()
      this.vectorFields.push(newField)
      vElement.appendChild(newField.e)
      newField.setChangeListener(vectorChangeHandler)
    })


    const classInstance = this
    function vectorChangeHandler(_coords){
      const vectors = []
      classInstance.vectorFields.forEach( f => {
        vectors.push(f.getValues())
      })
      // If the lattice vectors are valid (all of them different from a zero vector)
      if (noZeroVector(vectors))
        classInstance.moduleState.updateLatticeVectors(vectors, classInstance.checkbox.checked)
    }


    function noZeroVector(vectors){
      return ( !isZero(vectors[0]) && !isZero(vectors[1]) && !isZero(vectors[2]) )

      function isZero(a){
        return a[0] === 0 && a[1] === 0 && a[2] === 0
      }
    }

    this.checkbox = this.getElement('.lattice-scale-checkbox input')
  }


  /**
   * Updates the component to a structure change
   * Listener for the 'update structure' module level event
   * @param {Structure} structure
   * @param {object} _change
   */
  updateStructure(structure, _change=undefined){
    //console.log('LAT  updateStructure',structure.latVectors, this.vectorFields);
    const isPeriodic = structure.isAPeriodicSystem()

    this.getElement('.regular-view').style.display = (isPeriodic ? 'block' : 'none')
    this.getElement('.hidden-view').style.display = (isPeriodic ? 'none' : 'block')

    if (isPeriodic){
      const vectors = structure.latVectors
      for (let i = 0; i < this.vectorFields.length; i++)
        this.vectorFields[i].setValues(vectors[i])
    }
  }


  /**
   * Updates the component to a new structure
   * Listener for the 'new structure' module level event
   * @param {Structure} structure
   */
  setNewStructure(structure){
    this.updateStructure(structure)
  }

	selectStructure(structure){
		this.updateStructure(structure)
	}


  /**
   * Updates the vector number of decimals
   */
  updateVectorsDecimals(){
    this.vectorFields.forEach( vField => {
      vField.updateDecimals()
    })
  }
}



/**
 * UI panel enabling the supercell feature
 */
class Supercell extends UIComponent{

  /**
   * @param  {State} moduleState
   *   Object representing the state of the Structure builder module
   */
  constructor(moduleState){
    super('span', '#Supercell')

    this.setHTML(`
			<div class="regular-view">
          <div class="supercell"> <span class="SupercellField-box"></span></div>
			</div>
    `)

    this.moduleState = moduleState

    const supercellElement = this.getElement('.SupercellField-box')
		const newField = new SupercellField()
		this.supercellFields = newField
		supercellElement.appendChild(newField.e)

		let scButton = this.getElement('.create-button')
		// console.log(scButton)
		scButton.addEventListener('click',scChangeHandler)

    const classInstance = this
    function scChangeHandler(){
      const scNums = []
			scNums.push(classInstance.supercellFields.getValues())
      // If the lattice vectors are valid (all of them different from a zero vector)
      if (isValidSC(scNums[0])){

        classInstance.supercellFields.setValues([])

        if (!classInstance.moduleState.repeatStructure(scNums[0]))  // scNums[0] = supercellDim
          UserMsgBox.setError(STRUCT_TOO_BIG_MSG)
      }
    }

    function isValidSC(scNums){
			let isValid = true
			if (scNums.length === 9) {
				// scNums.forEach(num =>{
				// 	isValid = isValid && num >= 0
				// })
			} else {
				scNums.forEach(num =>{
					isValid = isValid && (num > 0)
				})
			}
      return isValid
			// scNums[0][0] > 0 && scNums[0][1] > 0 && scNums[0][2] > 0
    }
  }


  /**
   * Updates the component to a structure change
   * Listener for the 'update structure' module level event
   * @param {Structure} structure
   * @param {object} _change
   */
  updateStructure(structure, _change=undefined){
    //console.log('LAT  updateStructure',structure.latVectors, this.vectorFields);
    const isPeriodic = structure.isAPeriodicSystem()

    this.getElement('.regular-view').style.display = (isPeriodic ? 'inline-block' : 'none')
  }


  /**
   * Updates the component to a new structure
   * Listener for the 'new structure' module level event
   * @param {Structure} structure
   */
  setNewStructure(structure){
    this.updateStructure(structure)
  }

	selectStructure(structure){
    this.updateStructure(structure)
  }

} // class Supercell


/**
 * UI panel enabling the surface construction feature
 */
 class Surface extends UIComponent{

  /**
   * @param  {State} moduleState
   *   Object representing the state of the Structure builder module
   */
  constructor(moduleState){
    super('span', '#Surface')

    this.setHTML(`
			<div class="regular-view">
          <div class="surface"> <span class="SurfaceConstructionField-box"></span></div>
			</div>
    `)

    this.moduleState = moduleState

    const surfaceElement = this.getElement('.SurfaceConstructionField-box')
		const newField = new SurfaceConstructionField()
		this.surfaceField = newField
		surfaceElement.appendChild(newField.e)

		let sfButton = this.getElement('.create-button')
		sfButton.addEventListener('click', sfChangeHandler)

    let termButton = this.getElement('.terminate-button')
		termButton.addEventListener('click', sfTerminate)

    const classInstance = this
    function sfChangeHandler(){
      const sfData = classInstance.surfaceField.getCreationValues()
      if (isValid(sfData)){
        classInstance.surfaceField.clearValues()
        classInstance.moduleState.makeSlab(sfData)
      } else {
        UserMsgBox.setError(SURFACE_GENERATION_ERROR_MSG)
      }
    }

    function sfTerminate(){
      const termData = classInstance.surfaceField.getTermValues()
      if (termData[0] < termData[1]){
        classInstance.moduleState.makeSlab({'terminations': termData})
      } else {
        UserMsgBox.setError('Empty slab with this input')
      }
    }

    function isValid(sfData){
      let isValid = true
      isValid = isValid && sfData["miller"].reduce((a, b) => a + b, 0) > 0
      isValid = isValid && sfData["layers"] > 0
      isValid = isValid && sfData["vacuum"] > 0
      return isValid
    }
  }

  /**
   * Updates the component to a structure change
   * Listener for the 'update structure' module level event
   * @param {Structure} structure
   * @param {object} _change
   */
  updateStructure(structure, _change=undefined){
    this.surfaceField.showTermination(structure.fileSource)
    this.surfaceField.clearValues()
    if (typeof structure.extraData == 'object' && 'terminations' in structure.extraData){
      this.surfaceField.populateTerminationBoxes(structure.extraData['terminations'])
    }
  }

  /**
   * Updates the component to a new structure
   * Listener for the 'new structure' module level event
   * @param {Structure} structure
   */
  setNewStructure(structure){
    this.updateStructure(structure)
  }

	selectStructure(structure){
    this.updateStructure(structure)
  }

} // class Surface

