/**
* @author Sebastian Kokott
*
* @fileOverview File holding the Structure Info UI component
*/

import UIComponent from '../common/UIComponent.js'
import {Conf} from "../Conf";
import * as AppState from "../State";


export default class StructureInfo extends UIComponent{
  constructor(moduleState) {
    super('span', '.StructureInfoField')
    this.moduleState = moduleState // We might need this to update the info panel later on...
    this.symThresRowHTML = `
      <td>Symmetry threshold</td>
      <td><input id="thresh-input" type="text" style="width:30%;"/>&nbsp;Å &nbsp;
      <button id="thresh-button" style="width:40%;">Apply</button></td>
    `
    this.setHTML(`
        <div class="StructureInfo">
          <table>
          <tr>${this.symThresRowHTML}</tr>
          </table>
        </div>
    `)
    let threshEl = this.getElement('#thresh-input')
    threshEl.value = Conf.settings.symmetryThreshold
    let button = this.getElement('#thresh-button')
    button.addEventListener("click", _ => {
      Conf.settings.symmetryThreshold = threshEl.value
      AppState.updateSettings(Conf.settings)
    })
  }

  setNewStructure(structure){
    if (structure.structureInfo === undefined){
      structure.structureInfo = {}
    }
    this.updateStructureInfo()
  }

  selectStructure(_structure){
    this.updateStructureInfo()
  }

  updateTable(structureInfo) {
    let table = this.getElement('table')
    table.innerHTML = ''
    if (structureInfo !== undefined) {
      for (const [_, value] of Object.entries(structureInfo)) {
        if (value.info_str.includes("Symmetry")) continue
        let tr = document.createElement("tr")
        let td1 = document.createElement("td")
        td1.innerHTML = value.info_str
        let td2 = document.createElement("td")
        if (Array.isArray(value.value)) {
          td2.innerHTML = value.value.join(', ')
        } else
          td2.innerHTML = value.value
        tr.appendChild(td1)
        tr.appendChild(td2)
        table.appendChild(tr)
      }
    }
    let tr = document.createElement("tr")
    tr.innerHTML = this.symThresRowHTML
    table.appendChild(tr)
    let threshEl = this.getElement('#thresh-input')
    threshEl.value = Conf.settings.symmetryThreshold
    let button = this.getElement('#thresh-button')
    button.addEventListener("click", _ => {
      Conf.settings.symmetryThreshold = threshEl.value
      AppState.updateSettings(Conf.settings)
    })
  }

  updateStructure(){
    this.updateStructureInfo()
  }

  updateStructureInfo() {
    let structure = this.moduleState.getStructure()
    structure.updateInfo().then(() => this.updateTable(structure.structureInfo))
  }

  updateSettings() {
    this.updateStructureInfo()
  }

}
